import React from "react";
import Img from "gatsby-image";

const Skill = ({ sk }) => (
  <div className="col-md-3 col-sm-6 mb-30">
    <div className="portfolio-card">
      <Img
        fluid={sk.image}
        className="portfolio-thumb"
        alt="Mobile Development"
        title="Mobile Development"
      />
      <div className="portfolio-card-body">
        <h5 className="portfolio-title">{sk.title}</h5>
        {sk.list.map((i, k) => (
          <span key={k} className="tag-link">
            {i}
          </span>
        ))}
      </div>
    </div>
  </div>
);

const SkillSet = ({ skills }) => (
  <section className="pb-5 mb-4" data-offset-top="105" id="works">
    <div
      className="bg-white mx-auto px-3 px-xl-5 pt-4 pb-5 box-shadow"
      style={{ maxWidth: "1300px" }}
    >
      <h2 className="h3 block-title text-center">{skills.title}</h2>
      <div className="row pt-4">
        {skills.sets.map((grp, k) => (
          <Skill key={k} sk={grp} />
        ))}
      </div>
    </div>
  </section>
);

export default SkillSet;
