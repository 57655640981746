import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Hero = ({ background, title, subTitle, buttonText, dark = false }) => (
  <StaticQuery
    query={graphql`
      query {
        shape: file(relativePath: { eq: "hero-shape.png" }) {
          childImageSharp {
            fixed(width: 452, height: 448) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        Tag="section"
        className="bg-cover bg-center bg-no-repeat py-5 hero"
        fluid={background}
      >
        <span className="bg-overlay" style={{ opacity: 0.3 }}></span>
        <div className="container bg-content py-md-5">
          <div className="row justify-content-center py-md-5">
            <div className="col-12 text-center py-md-5">
              <BackgroundImage
                Tag="div"
                className="d-table w-100 bg-no-repeat bg-center hero-shape"
                fixed={data.shape.childImageSharp.fixed}
                style={{
                  minHeight: "448px",
                  backgroundSize: "452px 448px"
                }}
              >
                <div className="d-table-cell align-middle">
                  <h2
                    className={`display-4 text-shadow ${
                      dark ? "text-dark" : "text-white"
                    }`}
                  >
                    {title}
                  </h2>
                  <p
                    className={`text-xl pb-4 text-shadow ${
                      dark ? "text-dark" : "text-white"
                    }`}
                  >
                    {subTitle}
                  </p>
                  <Link
                    className={`btn btn-style-4 ${
                      dark ? "btn-dark" : "btn-light"
                    }`}
                    to="/contact-us/"
                  >
                    {buttonText}
                  </Link>
                </div>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </BackgroundImage>
    )}
  />
);

export default Hero;
