import React from "react";

const WhyUs = ({ why }) => (
  <section className="container pt-2 pb-5 mb-3" id="whyus">
    <h2 className="h3 block-title text-center">{why.title}</h2>
    <div className="row pt-4">
      <div className="col-md-6">
        <dl className="dl-with-icon">
          <dt>
            <i className="fe-icon-check-circle text-success" />
            {why.sets[0].title}
          </dt>
          <dd>{why.sets[0].desc}</dd>
          <dt>
            <i className="fe-icon-check-circle text-success" />
            {why.sets[1].title}
          </dt>
          <dd>{why.sets[1].desc}</dd>
        </dl>
      </div>
      <div className="col-md-6">
        <dl className="dl-with-icon">
          <dt>
            <i className="fe-icon-check-circle text-success" />
            {why.sets[2].title}
          </dt>
          <dd>{why.sets[2].desc}</dd>
          <dt>
            <i className="fe-icon-check-circle text-success" />
            {why.sets[3].title}
          </dt>
          <dd>{why.sets[3].desc}</dd>
        </dl>
      </div>
    </div>
  </section>
);

export default WhyUs;
