import React from "react";
import codingSVG from "../../content/images/icons/coding.svg";
import prototypeSVG from "../../content/images/icons/prototyping.svg";
import uiDesignSVG from "../../content/images/icons/ui-design.svg";
import userDataSVG from "../../content/images/icons/user-data.svg";

const HowItWorks = ({
  title1,
  desc1,
  title2,
  desc2,
  title3,
  desc3,
  title4,
  desc4
}) => (
  <section className="container py-5" id="about">
    <h2 className="h3 block-title text-center pt-2">How It Works</h2>
    <div className="row py-2">
      <div className="col-lg-3 col-sm-6">
        <div className="step step-with-icon">
          <div className="step-number">1</div>
          <div className="step-icon">
            <img src={userDataSVG} alt={title1} />
          </div>
          <h3 className="step-title">{title1}</h3>
          <p className="step-text text-sm">{desc1}</p>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="step step-with-icon">
          <div className="step-number">2</div>
          <div className="step-icon">
            <img src={prototypeSVG} alt={title2} />
          </div>
          <h3 className="step-title">{title2}</h3>
          <p className="step-text text-sm">{desc2}</p>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="step step-with-icon">
          <div className="step-number">3</div>
          <div className="step-icon">
            <img src={uiDesignSVG} alt={title3} />
          </div>
          <h3 className="step-title">{title3}</h3>
          <p className="step-text text-sm">{desc3}</p>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="step step-with-icon">
          <div className="step-number">4</div>
          <div className="step-icon">
            <img src={codingSVG} alt={title4} />
          </div>
          <h3 className="step-title">{title4}</h3>
          <p className="step-text text-sm">{desc4}</p>
        </div>
      </div>
    </div>
  </section>
);

export default HowItWorks;
