import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const CTA = () => (
  <StaticQuery
    query={graphql`
      query {
        bg: imageSharp(fluid: { originalName: { eq: "cta-bg.png" } }) {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        Tag="section"
        className="bg-center-top bg-no-repeat py-5 bg-primary"
        fluid={data.bg.fluid}
      >
        <div className="container px-4 py-5">
          <div className="row justify-content-center py-3">
            <div className="col-xl-7 col-lg-9">
              <h2 className="block-title text-white text-center pb-4">
                Let's have a chat!
              </h2>
              <div className="pt-4 text-center">
                <Link className="btn btn-style-4 btn-light" to="/contact-us/">
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    )}
  />
);

export default CTA;
